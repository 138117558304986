import React from 'react';

const Termofuse = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8  mt-3 bg-white dark:bg-gray-800 dark:text-white text-gray-800 md:px-6 md:py-12">
      <h1 className="text-2xl font-bold mb-4 md:text-3xl md:mb-6">Terms of Use</h1>

      <p className="mb-3 md:mb-4">
        Welcome to our Cricket Live Score website. By accessing and using our site, you agree to the following terms and conditions.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-3 md:text-2xl md:mt-8 md:mb-4">1. Use of the Website</h2>
<p className="mb-3 md:mb-4">
  You agree to use this site only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the site.
</p>
<p className="mb-3 md:mb-4">
  Any unauthorized use of the website, including but not limited to hacking, uploading harmful or malicious software, or attempting to interfere with the functionality of the website, is strictly prohibited.
</p>
<p className="mb-3 md:mb-4">
  You shall not use the website for any activity that is unlawful, fraudulent, or harms the reputation of the platform or its users.
</p>
<p className="mb-3 md:mb-4">
  By accessing and using the website, you agree not to submit false or misleading information or impersonate any individual or entity. Users must ensure that all personal information shared is accurate and up-to-date.
</p>
<p className="mb-3 md:mb-4">
  We reserve the right to suspend or terminate access to the website for users found violating these terms, engaging in prohibited behavior, or attempting to misuse the platform in any manner.
</p>


<h2 className="text-xl font-semibold mt-6 mb-3 md:text-2xl md:mt-8 md:mb-4">2. Intellectual Property</h2>
<p className="mb-3 md:mb-4">
  All content on this site, including text, graphics, logos, and images, is the property of our website or its content suppliers and is protected by intellectual property laws.
</p>
<p className="mb-3 md:mb-4">
  Any unauthorized use of the content, including reproduction, distribution, modification, or public display, without prior written consent, is strictly prohibited and may result in legal action.
</p>
<p className="mb-3 md:mb-4">
  The trademarks, service marks, and logos displayed on this site are registered and unregistered trademarks owned by our website or third-party licensors. Any use of these trademarks without prior authorization is prohibited.
</p>
<p className="mb-3 md:mb-4">
  Users are granted a limited, non-exclusive, non-transferable license to access and use the site solely for personal and non-commercial purposes, provided they adhere to these terms.
</p>
<p className="mb-3 md:mb-4">
  We reserve the right to terminate any licenses granted for content use if there is a violation of these terms or misuse of intellectual property.
</p>


<h2 className="text-xl font-semibold mt-6 mb-3 md:text-2xl md:mt-8 md:mb-4">3. Limitation of Liability</h2>
<p className="mb-3 md:mb-4">
  We are not liable for any damages arising from the use of or inability to use the site, including any errors or interruptions.
</p>
<p className="mb-3 md:mb-4">
  This includes, but is not limited to, damages for loss of data, loss of revenue or profits, loss of business, or other financial losses resulting from your use of the site or reliance on the information provided.
</p>
<p className="mb-3 md:mb-4">
  We shall not be held responsible for any indirect, incidental, special, or consequential damages, even if we have been advised of the possibility of such damages.
</p>
<p className="mb-3 md:mb-4">
  Our liability in all cases shall be limited to the extent permitted by applicable law and shall not exceed the amount paid by the user, if any, to access or use the site.
</p>
<p className="mb-3 md:mb-4">
  We are not responsible for any delays, disruptions, or failures in performance caused by circumstances beyond our reasonable control, including but not limited to acts of nature, technical failures, or unauthorized access.
</p>


      <h2 className="text-xl font-semibold mt-6 mb-3 md:text-2xl md:mt-8 md:mb-4">4. User-Generated Content</h2>
      <p className="mb-3 md:mb-4">
        If you post content on our site, you grant us a non-exclusive, royalty-free license to use, reproduce, and distribute such content.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-3 md:text-2xl md:mt-8 md:mb-4">5. External Links</h2>
      <p className="mb-3 md:mb-4">
        Our site may include links to third-party websites. We are not responsible for the content or practices of these external sites.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-3 md:text-2xl md:mt-8 md:mb-4">6. Modifications to Terms</h2>
      <p className="mb-3 md:mb-4">
        We may revise these terms at any time by updating this page. Your continued use of the site indicates your acceptance of any changes.
      </p>

      <p className="mt-6 text-sm text-gray-600 md:mt-8">
        {/* If you have any questions about these terms, please contact us at support@example.com. */}
      </p>
    </div>
  );
};

export default Termofuse;
