import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 mt-3 rounded-xl border dark:border-none bg-white dark:bg-gray-800 dark:text-white text-blue-950 md:px-6 md:py-12">

      <header class="bg-blue-950 text-white py-4">
        <div class="container mx-auto px-4">
          <h1 class="text-2xl font-medium">Privacy Policy</h1>
        </div>
      </header>

      <main class="container text-sm mx-auto px-4 py-6">
        <section class="bg-white dark:bg-gray-800 dark:text-white p-6 rounded shadow mb-6">
          <h2 class="text-xl font-medium mb-4">Introduction</h2>
          <p class="mb-4">Criczap ("Criczap app" or "Criczap platform" or "We" or "Our") is a mobile and web-based application developed by Criczap ("Company" or "We"). We are committed to providing you with an efficient and seamless experience while prioritizing your privacy and ensuring the protection of your personal data.</p>
          <p className='mb-4'>Our aim is to maintain transparency in the handling of data we collect from our users, detailing how it is utilized and guaranteeing it is never shared with third parties without authorization.</p>
          <p className='mb-4'>This Privacy Policy is applicable to all users who access and use the Website(s)/Mobile Application(s) operated by Criczap. By continuing to browse or use our Website(s)/Mobile Application(s), you acknowledge and agree to the terms outlined in this Privacy Policy and the associated Terms of Use.</p>
          <p>We value the privacy of our users and are devoted to safeguarding it in every way. Any concerns or disputes regarding privacy shall be addressed in alignment with this Privacy Policy and the Terms of Use available on our Website(s)/Mobile Application(s). If you do not consent to the terms outlined in this Privacy Policy, we request that you refrain from accessing or using our services.</p>
        </section>

        <section class="bg-white dark:bg-gray-800 dark:text-white p-6 rounded shadow mb-6">
          <h2 class="text-xl font-medium mb-4">Personal Information</h2>
          <p class="mb-4">This Privacy Policy complies with:</p>
          <ul class="list-disc list-inside mb-4">
            <li>Section 43A of the Information Technology Act, 2000;</li>
            <li>Rule 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 ("SPI Rules");</li>
            <li>Relevant sections of the Consumer Protection Act, 2019, and the Consumer Protection (E-Commerce) Rules, 2020.</li>
          </ul>
          <p class="mb-4">"Personal Information" as defined under the SPI Rules refers to information about an individual that can identify them directly or indirectly, either alone or in combination with other information.</p>
          <div class="">
            <h2 class="text-xl font-medium mb-4">Sensitive Personal Data or Information Includes:</h2>
            <ul class="list-disc list-inside space-y-2">
              <li>Passwords;</li>
              <li>Financial information such as bank account or payment details;</li>
              <li>Health and medical data;</li>
              <li>Identification details like name, address, email, phone number, or date of birth;</li>
              <li>Biometric data;</li>
              <li>Visitor information submitted during registration;</li>
              <li>Call data records.</li>
            </ul>
            <p class="mt-4">All such data collectively constitutes <strong>"Personal Information."</strong></p>

            <h2 class="text-xl font-medium mt-6 mb-4">The Information We Collect Includes:</h2>
            <ul class="list-disc list-inside space-y-2">
              <li>Data voluntarily provided by users, such as during registration;</li>
              <li>Information automatically captured during navigation (e.g., cookies).</li>
            </ul>

            <p class="mt-4">By using our Website(s)/Mobile Application(s), you consent to the collection, storage, and utilization of your Personal Information, including updates made to it at a later stage, for the purpose of providing services.</p>

            <h2 class="text-xl font-medium mt-6 mb-4">For Some Services, You May Be Required to Provide:</h2>
            <ul class="list-disc list-inside space-y-2">
              <li>Your name, email address, gender, age, postal code, and occupation;</li>
              <li>Financial details like credit/debit card information;</li>
              <li>Medical history or biometric data.</li>
            </ul>

            <p class="mt-4">In cases where users under 18 provide Personal Information, we assume parental or guardian consent has been obtained.</p>
          </div>
        </section>

        <section class="bg-white dark:bg-gray-800 dark:text-white p-6 rounded shadow mb-6">
          <h2 class="text-xl font-medium mb-4">Reasonable Security Practices</h2>
          <p class="mb-4">We adhere to Rule 8 of the SPI Rules and the Information Technology Act, 2000, to implement security measures that protect user information. These include internationally recognized practices for technical, operational, and managerial security.</p>
          <p>Despite our stringent measures, no system is impenetrable. While we strive to protect your data, we cannot guarantee complete security against unauthorized access, disclosure, or alteration.</p>
        </section>

        <section class="bg-white dark:bg-gray-800 dark:text-white p-6 rounded shadow mb-6">
          <h2 class="text-xl font-medium mb-4">Cookies</h2>
          <p class="mb-4">To enhance your experience, we may use cookies or similar technologies to gather data and personalize our services. Users can manage their cookie preferences in their browser settings. Refusal of cookies may affect your ability to access certain features.</p>
        </section>

        <section class="bg-white dark:bg-gray-800 dark:text-white p-6 rounded shadow mb-6">
          <h2 class="text-xl font-medium mb-4">Information Sharing</h2>
          <p class="mb-4">Criczap may disclose Personal Information under the following circumstances:</p>
          <ul class="list-disc list-inside mb-4">
            <li>To comply with legal obligations or government requests.</li>
            <li>Within affiliated companies for processing or operational purposes.</li>
            <li>With third-party service providers for targeted advertising (non-sensitive data only).</li>
          </ul>
        </section>

        <section class="bg-white dark:bg-gray-800 dark:text-white p-6 rounded shadow">
          <h2 class="text-xl font-medium mb-4">Grievance Redressal</h2>
          <p>If you have any concerns, please contact us at <a href="mailto:criczapapp@gmail.com" class="text-blue-600 underline">criczapapp@gmail.com</a>.</p>
        </section>
      </main>






    </div>
  );
};

export default PrivacyPolicy;
